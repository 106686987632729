<template>
  <div id="app" class :class="{ 'modal-fix': applyModalFix }">
    <!--
    We will use this later but disable it until we have the time!
    <Toast :message="showMessage" v-if="showMessage.show" /> 
    -->

    <div class="resize-observer">
      <resize-observer @notify="handleResize" />
    </div>

    <transition name="fade" mode="out-in">
      <NavigationMenu v-if="showTopNav && !onTjingMobile" />
    </transition>

    <div class="container" :class="{ spacer: showTopNav }">
      <transition name="main-fade" mode="out-in">
        <router-view />
      </transition>
    </div>

    <transition name="slide-in-top" mode="out-in">
      <MobileMenu
        v-if="showMobileMenu"
        @swapModals="swapModals"
        @closeModal="closeModal"
        @logout="logout()"
      />
    </transition>

    <transition name="fade" mode="out-in">
      <TjingFooter v-if="showFooter && !onTjingMobile" />
      <div class="tjing-mobile-padding" v-else-if="onTjingMobile"></div>
    </transition>

    <transition name="slide-in-message" mode="out-in">
      <StatusPopup :message="showMessage" v-if="showMessage.show" />
    </transition>

    <transition name="fade" mode="out-in">
      <VerifyEmailBanner v-if="showEmailVerificationBanner" />
    </transition>
  </div>
</template>

<script>
import MobileMenu from '@/components/MobileMenu'
import StatusPopup from '@/components/StatusPopup'
import VerifyEmailBanner from '@/components/VerifyEmailBanner'
//import Toast from '@/components/Toast.vue'
export default {
  name: 'Tjing',
  components: {
    MobileMenu,
    StatusPopup,
    //Toast, See comment above
    VerifyEmailBanner,
  },
  watch: {
    applyModalFix: function () {
      if (this.applyModalFix) {
        document.body.classList.add('modal-open')
      } else {
        document.body.classList.remove('modal-open')
      }
    },
  },
  computed: {
    showEmailVerificationBanner() {
      if (this.$router.currentRoute.name == 'verifyemail') {
        return false
      }
      return this.$store.getters.showEmailVerificationBanner
    },
    showMessage() {
      return this.$store.getters.showMessage
    },
    applyModalFix() {
      if (this.$store.state.utility.deviceWidth < 772) {
        if (
          this.$store.getters.isAModalOpen ||
          this.showMobileMenu ||
          this.$store.getters.eventInformation.showInfo
        ) {
          return true
        } else return false
      } else {
        if (this.$store.getters.isAModalOpen || this.showMobileMenu) {
          return true
        } else return false
      }
    },
    showMobileMenu() {
      return this.$store.getters.showMobileMenu
    },
    onTjingMobile() {
      return this.$store.getters.onTjingMobile
    },
    showTopNav() {
      return this.$store.getters.showTopNav
    },
    showFooter() {
      return this.$store.getters.showFooter
    },
    individualStatsColSpan() {
      if (window.innerWidth > 992) {
        return 20
      } else {
        return 22
      }
    },
    isUserLoggedIn() {
      if (this.$store.getters.isUserLoggedIn) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
      this.$store.dispatch('toggleMobileMenu', false)
    },
    swapModals(to) {
      if (to == 'login') {
        this.$store.dispatch('toggleModal', 'showLoginModal', true)
        this.$store.dispatch('toggleMobileMenu', false)
      } else if (to == 'register') {
        this.$store.dispatch('toggleMobileMenu', false)
      } else if (to == 'password') {
        this.$store.dispatch('toggleMobileMenu', false)
      }
    },
    closeModal() {
      this.$store.dispatch('toggleMobileMenu', false)
    },
    handleResize({ width }) {
      this.$store.dispatch('deviceWidth', width)
    },
    isMobile() {
      if (window.innerWidth < 543) {
        return true
      } else {
        return false
      }
    },
  },
  mounted() {
    if (this.onTjingMobile) {
      const meta = document.createElement('meta')
      meta.setAttribute('name', 'viewport')
      meta.setAttribute(
        'content',
        'width=device-width, initial-scale=1.0, maximum-scale=1.0'
      )
      document.getElementsByTagName('head')[0].appendChild(meta)
    } else {
      const meta = document.createElement('meta')
      meta.setAttribute('name', 'viewport')
      meta.setAttribute('content', 'width=device-width, initial-scale=1.0')
      document.getElementsByTagName('head')[0].appendChild(meta)
    }
    this.$store.dispatch('deviceWidth', window.innerWidth)
  },
}
</script>

<style lang="scss" scoped>
.tjing-mobile-padding {
  margin-bottom: 160px;
}
#app {
  position: relative;
}

.main-fade-enter-active,
.main-fade-leave-active {
  transition: opacity 0.3s;
}
.main-fade-enter, .main-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.slide-in-top-enter {
  right: -100vw;
  width: auto;
}

.slide-in-top-enter-to {
  top: 50px;
  right: 0px;
  width: 100vw;
  opacity: 1;
}
.slide-in-top-leave-active {
  right: -100vw;
}

.modal-fix {
  overflow-y: hidden;
  max-height: 100vh;
}

.resize-observer {
  position: relative;
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
  .spacer {
    margin-top: 60px;
  }
}
</style>
