<template>
  <button
    :disabled="disabled"
    @click="emitClick()"
    class="tjing-button"
    :class="[variant, { disabled: disabled }, color, mobileColor]"
  >
    <slot name="before" />
    <div class="lds-ellipsis" v-if="locked">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <p v-else-if="title">
      <b>{{ title }}</b>
    </p>
    <slot name="after" />
  </button>
</template>

<script>
export default {
  name: 'TjingButton',
  props: {
    title: {
      type: String,
    },
    color: {
      type: String,
      default: 'midnight',
    },
    mobileColor: {
      type: String,
      default: 'm-cloud',
    },
    variant: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    locked: { type: Boolean, default: false },
  },
  methods: {
    emitClick() {
      if (!this.locked && !this.disabled) {
        this.$emit('click')
      }
    },
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {},
}
</script>

<style lang="scss" scoped>
@import '@/styles/Typography';
.tjing-button {
  user-select: none;
  font-size: 1rem;
  height: 48px;
  border: 1px solid $cloud;
  background: $cloud;
  transition: all ease 0.3s;
  border-radius: 6px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  p {
    font-size: 1rem;
    letter-spacing: 0.16px;
  }

  &.solid {
    background-color: $midnight;

    &.dusk {
      background-color: $dusk;
    }
    &.twilight {
      background-color: $twilight;
      border: 1px solid $twilight;
    }

    p {
      color: white;
    }

    &:hover {
      &.solid {
        background-color: black;

        &:disabled {
          background-color: $fog;
        }
        p {
          color: white;
        }

        &.dusk {
          background-color: $dusk;
        }
        &.twilight {
          background-color: $twilightshade;
          border-color: $twilightshade;
        }
      }
    }
  }

  &:hover {
    background: $mist;
  }

  &.m-midnight {
    background: white;
    border-color: $dusk;
    color: $dusk;

    &:hover {
      background-color: #ffe5ef;
    }
  }

  &.m-dusk {
    background: white;
    border-color: $dusk;
    color: $dusk;

    &:hover {
      background-color: #ffe5ef;
    }
  }

  &:disabled {
    border: 1px solid $fog;
    background: $fog;

    &.solid {
      border-color: $fog;
      color: $fog;

      &.twilight {
        border-color: $fog;
        background-color: $fog;
        color: $blizzard;
      }
    }

    &:hover {
      background: $fog;
    }
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 6px;
}

.lds-ellipsis div {
  position: absolute;
  top: 0px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 4px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 4px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 16px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 28px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(12px, 0);
  }
}
@media (min-width: 768px) {
  .tjing-button {
    border: 1px solid $midnight;
    background: white;

    &.midnight {
      border-color: $midnight;
      color: $midnight;

      &:disabled {
        border-color: $fog;
        &:hover {
          background: $fog;
        }
      }

      &:hover {
        background-color: #f5f5f6;
      }
    }

    &.dusk {
      border-color: $dusk;
      color: $dusk;

      &:hover {
        background-color: #ffe5ef;
      }

      &:disabled {
        border: 1px solid $fog;
        background: $fog;

        &:hover {
          background: $fog;
        }
      }
    }
  }
}
</style>
